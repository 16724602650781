'use client'

import { delay } from '@/utils/delay'
import React, { createContext, useState, ReactNode, useCallback } from 'react'
import {
  ModalAnimationStateType,
  ModalContextType,
  ModalOptionsType,
  ModalType,
} from './types'

export const ModalContext = createContext<ModalContextType | undefined>(
  undefined
)

const defaultOptions = {
  animationMs: 300,
  position: 'bottom' as ModalOptionsType['position'],
  modalProps: {},
}

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [modalName, setModalName] = useState<ModalType>('')
  const [animationState, setAnimationState] =
    useState<ModalAnimationStateType>('idle')
  const [options, setOptions] = useState(defaultOptions)
  const isOpen = !!modalName

  const open = useCallback(
    async (name: ModalType, _options?: ModalOptionsType) => {
      if (options) {
        setOptions((prevOptions) => ({ ...prevOptions, ..._options }))
      }
      setAnimationState('opening')
      setModalName(name)
      await delay(options.animationMs)
      setAnimationState('idle')
    },
    [modalName, animationState, options]
  )

  const close = useCallback(async () => {
    setAnimationState('closing')
    await delay(options.animationMs)
    setAnimationState('idle')
    setModalName('')
    setOptions({ ...defaultOptions, modalProps: options.modalProps }) // not cleaning modal to pass to next modal if needed
  }, [modalName, animationState, options])

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        open,
        close,
        animationState,
        name: modalName,
        options: options as Required<ModalOptionsType>,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
