'use client'

import { FAVOURITE } from '../../constants'
import { useUser } from '@/hooks/use-user'
import s from './fav-icon.module.css'
import { V2StarIcon } from '@/v2/icons/starIcon/starIcon'
import { useRegisterModal } from '@/hooks/use-register-modal'

interface FavouriteIconProps {
  itemId: number
  type: FAVOURITE
  onUnauthenticatedClick?: () => void
}

export const FavouriteIcon = (props: FavouriteIconProps) => {
  const { itemId, type } = props
  const user = useUser()
  const isFavourite = user.hasFavourite(itemId, type)
  const registerModal = useRegisterModal()

  const handleOnClickFavorite = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation()
    e.preventDefault()

    if (user.isReader) {
      user.toggleFavourite(itemId, type)
    } else {
      registerModal.open()
    }
  }

  if (user.isSupplier || user.isAdmin) {
    return null
  }

  return (
    <button
      className={s.button}
      onClick={handleOnClickFavorite}
      aria-label="Add to favorite"
    >
      <V2StarIcon filled={isFavourite} isBlack={false} />
    </button>
  )
}
