type Props = React.SVGAttributes<SVGElement>

export const StartOutlinedIcon = (props: Props) => {
  return (
    <svg
      fill="#fff"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <polygon
        points="12 4 9.22 9.27 3 10.11 7.5 14.21 6.44 20 12 17.27 17.56 20 16.5 14.21 21 10.11 14.78 9.27 12 4"
        style={{
          fill: 'none',
          stroke: '#fff',
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 2,
        }}
      />
    </svg>
  )
}
