import { ModalContext } from '@/context/modal-context/modal-context'
import { useContext } from 'react'

export const useModal = () => {
  const context = useContext(ModalContext)
  if (context === undefined) {
    throw new Error(
      'RMW dev error: useMenu must be used within a ModalProvider'
    )
  }

  return context
}
