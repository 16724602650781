import { useScreen, useWindowSize } from 'usehooks-ts'
import { useModal } from './use-modal'
import { ModalOptionsType } from '@/context/modal-context/types'

/**
 * this is just a wrapper for the useModal hook
 * that opens the register modal with a specific position
 * if the screen width is greater than 768
 */

export function useRegisterModal() {
  const modal = useModal()
  const { width = 0 } = useWindowSize()

  if (width > 768) {
    return {
      open: (options?: ModalOptionsType) =>
        modal.open('register', { position: 'right', ...options }),
      close: () => modal.close(),
    }
  }

  return {
    open: (options?: ModalOptionsType) =>
      modal.open('register', { position: 'bottom', ...options }),
    close: () => modal.close(),
  }
}
