import { API_URL, API_V1 } from '@/constants'

export const logOut = async () => {
  const response = await fetch(`${API_V1}${API_URL.AUTH.LOGOUT}`, {
    method: 'POST',
  })
  if (response.ok) {
    localStorage.clear()
    if (typeof window !== undefined || typeof window !== 'undefined') {
      window.location.reload()
    }
  }
}
