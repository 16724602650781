import {
  API_URL,
  API_V1,
  DOMAIN_ACTION,
  FAVOURITE,
  GTM_EVENT,
  LOCAL_STORAGE_KEYS,
  ROUTES,
} from '@/constants'
import { useStores } from '@/store/rootStoreContext'
import { ApiFavourite, ICurrentUser } from '@/utils/auth/auth'
import { logOut } from '@/utils/auth/log-out'
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime'
import { useRouter } from 'next/navigation'
import { useCallback } from 'react'
import useSWR, { Fetcher } from 'swr'
import { sendGTMEvent } from '@next/third-parties/google'

type LoginOptionsType = {
  redirect?: {
    reader?: string
    supplier?: string
    admin?: string
  }
}

const fetcher: Fetcher<ICurrentUser, string> = async (url: string) => {
  try {
    const res = await fetch(url, {
      credentials: 'include',
      method: 'GET',
    })

    if (!res.ok) return null

    return res.json()
  } catch (error) {
    return null
  }
}

export function useUser() {
  const store = useStores()
  const { data: user, mutate } = useSWR(
    `${API_V1}${API_URL.USERS.CURRENT_USER}`,
    fetcher,
    {
      onSuccess: (userData) => {
        try {
          store.userProfile.setProfile(userData) // @todo remove this once getting rid of mobx userProfile
        } catch (e) {}

        const providerSignUpUrl = localStorage.getItem(
          LOCAL_STORAGE_KEYS.GTM_EVENT.PROVIDER_SIGN_UP_URL
        )

        if (userData?.role === 'reader' && providerSignUpUrl) {
          sendGTMEvent({
            event: GTM_EVENT.SIGN_UP,
            'page path': providerSignUpUrl,
          })

          localStorage.removeItem(
            LOCAL_STORAGE_KEYS.GTM_EVENT.PROVIDER_SIGN_UP_URL
          )
        }
      },
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (error.status === 401) return
        if (retryCount >= 1) return
        setTimeout(() => revalidate({ retryCount }), 10000)
      },
    }
  )
  const router = useRouter()

  const _logOut = useCallback(async () => {
    await logOut()
    router.push(ROUTES.HOME)
  }, [router])

  const hasFavourite = useCallback(
    (id: number, type: FAVOURITE) => {
      return !!user?.favourites.some(
        (fav) => fav.object_id === id && fav.type === type
      )
    },
    [user]
  )

  const toggleFavourite = useCallback(
    async (id: number, type: FAVOURITE) => {
      const userId = user?.id
      if (!userId) return

      try {
        const res = await fetch(DOMAIN_ACTION.API.FAVOURITE, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: userId,
            object_id: id,
            type,
          }),
        })

        let updatedFavourites = [...user.favourites]
        if (res.status === 200) {
          const index = user.favourites.findIndex(
            (fav) => fav.object_id === id && fav.type === type
          )

          if (index !== -1) {
            updatedFavourites = updatedFavourites.filter(
              (fav) => !(fav.object_id === id && fav.type === type)
            )
          }
        }

        if (res.status === 201) {
          updatedFavourites.push({ object_id: id, type } as ApiFavourite)
        }

        mutate(
          { ...user, favourites: updatedFavourites },
          { revalidate: false }
        )
      } catch (err) {
        console.log('error adding favourite', err)
      }
    },
    [user, mutate]
  )

  const logIn = async (
    email: string,
    password: string,
    options: LoginOptionsType = {}
  ): Promise<[Error, null] | [null, ICurrentUser]> => {
    const formData = new FormData()
    formData.append('email', email)
    formData.append('password', password)

    try {
      const res = await fetch(`${API_V1}${API_URL.AUTH.LOGIN}`, {
        method: 'POST',
        body: formData,
      })

      switch (res.status) {
        case 200:
          const refreshedUser = await mutate()
          redirectAdminAfterLogin(
            router,
            refreshedUser,
            options.redirect?.admin
          )
          redirectSupplierAfterLogin(
            router,
            refreshedUser,
            options.redirect?.supplier
          )
          redirectReaderAfterLogin(
            router,
            refreshedUser,
            options.redirect?.reader
          )

          return [null, refreshedUser!]
        case 400:
        case 401:
          return [new Error('Incorrect email or password'), null]
        default:
          return [new Error('Error logging in user'), null]
      }
    } catch (e: any) {
      return [e, null]
    }
  }

  return {
    user,
    isSupplier: user?.role === 'supplier',
    isReader: user?.role === 'reader',
    isAdmin: user?.role === 'admin',
    logOut: _logOut,
    logIn,
    hasFavourite,
    toggleFavourite,
    refreshUser: mutate,
    favourites: user?.favourites,
  }
}

function redirectSupplierAfterLogin(
  router: AppRouterInstance,
  user?: ICurrentUser,
  redirect?: string
) {
  if (!user || user.role !== 'supplier') {
    return
  }

  if (redirect) {
    router.push(redirect)

    return
  }

  /**
   * if user is supplier and has no subscription
   * redirect to supplier listing page
   */
  if (!(user?.stripe_subscription_id || user?.stripe_subscription_venue_id)) {
    const supplierListingId = user.suppliers[0]?.id
    router.push(
      `${ROUTES.SUPPLIER.LISTING}?supplier=${supplierListingId}&formName=account-details`
    )

    return
  }

  router.push(ROUTES.SUPPLIER.SUMMARY)
}

function redirectAdminAfterLogin(
  router: AppRouterInstance,
  user?: ICurrentUser,
  redirect?: string
) {
  if (!user || user.role !== 'admin') {
    return
  }

  if (redirect) {
    router.push(redirect)
    return
  }

  router.push(ROUTES.ADMIN.ROOT)
}

function redirectReaderAfterLogin(
  router: AppRouterInstance,
  user?: ICurrentUser,
  redirect?: string
) {
  if (!user || user.role !== 'reader') {
    return
  }

  if (redirect) {
    router.push(redirect)

    return
  }

  router.push(ROUTES.READER.DASHBOARD)
}
